// Native Cache for browsers.
// @mdn https://developer.mozilla.org/en-US/docs/Web/API/Cache

export async function getFromCache(
  request: Request,
  cacheDurationInMs: number = 0
) {
  if (cacheDurationInMs <= 0) return undefined;

  const cache = await openCacheForQuery();
  if (!cache) return undefined;

  const TIMESTAMP_KEY = createQueryCacheTimestampKey(request.url);
  const cachedResponse: Response | undefined = await cache.match(request.url);
  const timestamp =
    (await cache
      .match(TIMESTAMP_KEY)
      .then((res) => res?.text())
      .then((value) => (value ? Number(value) : 0))) || 0;

  if (cachedResponse) {
    if (Date.now() < timestamp) return cachedResponse;
    else await removeFromCache(request);
  }
}

export async function addToCache(
  request: Request,
  response: Response,
  cacheDuration: number
) {
  const cache = await openCacheForQuery();
  if (!cache) return;

  const TIMESTAMP_KEY = createQueryCacheTimestampKey(request.url);
  await Promise.all([
    cache.put(request.url, response),
    cache.put(
      TIMESTAMP_KEY,
      new Response((Date.now() + cacheDuration).toString())
    ),
  ]);

  return;
}

export async function removeFromCache(request: Request) {
  const cache = await openCacheForQuery();
  if (!cache) return;

  const TIMESTAMP_KEY = createQueryCacheTimestampKey(request.url);

  const [result] = await Promise.all([
    cache.delete(request, {
      ignoreMethod: true,
      ignoreSearch: true,
      ignoreVary: true,
    }),
    cache.delete(TIMESTAMP_KEY),
  ]);

  return result;
}

export async function openCacheForQuery(): Promise<Cache | undefined> {
  return typeof window === "undefined"
    ? undefined
    : await window?.caches?.open("query");
}

const TIMESTAMP_KEY = "+timestamp+";
/**
 * Timestamp should be stored separately as current
 * cache mechanism does not provide time of cache storage.
 */
export function createQueryCacheTimestampKey(url: string) {
  return url + TIMESTAMP_KEY;
}

/** Check expired requests and clear them */
export async function clearExpiredCache() {
  const cache = await openCacheForQuery();
  if (!cache) return;

  const now = Date.now();
  const currentUrlHost = new URL(window.location.href).host;

  const allRequests = await cache.keys();
  const toBeDeleted: Request[] = [];
  for (let i = 0; i < allRequests.length; i++) {
    const req = allRequests[i];
    // Only delete requests from the same host
    if (!req.url.includes(currentUrlHost)) continue;
    // Ignore timestamp key as they will be deleted along with the request
    if (req.url.includes(TIMESTAMP_KEY)) continue;

    const timestampKey = createQueryCacheTimestampKey(req.url);
    const timestamp = await cache
      .match(timestampKey)
      .then((res) => res?.text())
      .then((value) => (value ? Number(value) : 0));
    if (timestamp < now) toBeDeleted.push(req);
  }

  await Promise.all(toBeDeleted.map(removeFromCache));
}
