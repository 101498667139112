import { useCallback, useEffect, useRef, useState, useTransition } from "react";
import debounce from "lodash.debounce";
import {
  useStableRef,
  useStableCallback,
  useStableMemo,
  useEventListener,
  useInterval,
} from "@business-finland/wif-ui-lib";

import { ONE_MIN_IN_MS } from "../constants/durations";
import { addToCache, clearExpiredCache, getFromCache } from "../util/web-cache";

export enum QueryStatus {
  INIT = "init",
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
  CANCELLED = "cancelled",
}

export interface IQueryOptions<QueryData> {
  /** If set, this value will be used as the initial data for the query. */
  initialData?: QueryData | (() => QueryData);
  /** Set this to true to disable this query from automatically running. */
  disabled?: boolean;
  /** Fetching happens in background if there is previous data. No loading is shown. */
  enableBackgroundFetch?: boolean;
  /** Set headers to outgoing fetch call. */
  headers?: Headers;
  /** Callback executed when query's fetch is initiated. */
  onLoading?: () => void;
  /** Callback executed when query is successful. */
  onSuccess?: (data: QueryData) => void;
  /** Callback executed when query has failed. */
  onError?: (error: Error) => void;
  /** Callback executed when query in finished (success or fail) */
  onDone?: () => void;
  /** Refetch when internet is reconnected. @default true */
  refetchOnReconnect?: boolean;
  /** Store result in cache for some duration. */
  cacheDurationInMs?: number;
  /** Callback to select or transform query result into desired structure. */
  debounceDurationInMs?: number;
}

export interface IQueryResult<QueryData = unknown> {
  /** Current status of the query.  */
  status: QueryStatus;
  /** Init state. The query has not yet started. It might be using initialData.  */
  isInit: boolean;
  /** Success state */
  isSuccess: boolean;
  /** Resulting data. Can be undefined */
  data?: QueryData;
  /** Error state */
  isError: boolean;
  /** Error message. */
  errorMsg?: string;
  /** Loading state */
  isLoading: boolean;
  /** Callback to refetch the current url. */
  refresh: () => void;
  /** Callback to cancel ongoing fetch. */
  cancel: () => void;
  /** if query is disabled */
  isDisabled?: boolean;
}

/**
 * Hook to handle fetch requests with error handling and cache.
 *
 * @examples
 * - default
 *   ```tsx
 *   useQuery<JobsData>("/jobs");
 *   ```
 *
 * - with selector
 *   ```tsx
 *   const jobsQuery = useQuery<JobsData>("/jobs", { selector: (data) => data[0] });
 *   ```
 */
export default function useQuery<QueryData>(
  url: string | URL,
  options: Omit<IQueryOptions<QueryData>, "initialData"> & {
    initialData: QueryData | (() => QueryData);
  }
): Omit<IQueryResult<QueryData>, "data"> & { data: QueryData };
export default function useQuery<QueryData>(
  url: string | URL,
  options?: IQueryOptions<QueryData>
): IQueryResult<QueryData>;
export default function useQuery<QueryData>(
  url: string | URL,
  options: IQueryOptions<QueryData> = {}
): IQueryResult<QueryData> {
  const queryUrl = url.toString();
  const {
    initialData,
    disabled = false,
    headers,
    onLoading,
    onSuccess,
    onError,
    onDone,
    enableBackgroundFetch,
    refetchOnReconnect = true,
    cacheDurationInMs,
    debounceDurationInMs = 0,
  } = options;
  const abortControllerRef = useRef<AbortController>();

  const [, startTransition] = useTransition();
  const [status, setStatus] = useState<QueryStatus>(QueryStatus.INIT);
  const [data, setData] = useState(initialData);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const memoHeaders = useStableMemo(headers);
  const previousDataRef = useStableRef(data);

  const handleLoading = useStableCallback((backgroundFetch?: boolean) => {
    startTransition(() => {
      setErrorMsg(undefined);
    });

    if (
      (enableBackgroundFetch && Boolean(previousDataRef.current)) ||
      backgroundFetch
    ) {
      return;
    }

    startTransition(() => {
      onLoading?.();
      setStatus(QueryStatus.LOADING);
    });
  });

  const handleSuccess = useStableCallback((data: any) => {
    startTransition(() => {
      onSuccess?.(data);
      setData(data);
      setErrorMsg(undefined);
      setStatus(QueryStatus.SUCCESS);
    });
  });

  const handleError = useStableCallback((error: Error) => {
    startTransition(() => {
      setErrorMsg(error.message);
      const errorStatus =
        error.name === "AbortError" ? QueryStatus.CANCELLED : QueryStatus.ERROR;
      setStatus(errorStatus);

      if (errorStatus === QueryStatus.ERROR && onError) {
        onError(error);
      }
    });
  });

  const handleDone = useStableCallback(() => onDone?.());

  const initQuery = useCallback(
    async (backgroundFetch?: boolean): Promise<void> => {
      if (disabled) return;

      // cancel the previous request before new request
      abortControllerRef.current?.abort();
      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      handleLoading(backgroundFetch);

      const handleResponse = async (response: Response) => {
        const status = response.status;
        if (status >= 400) {
          const data = await response.json();
          throw new Error(String(data) || response.statusText);
        }

        const data = await response.json();
        if (abortController.signal.aborted)
          throw new DOMException(
            "Aborted while handling response",
            "AbortError"
          );

        return data;
      };

      const request = new Request(queryUrl, {
        headers: memoHeaders,
        signal: abortController.signal,
      });

      fetchQueryWithCache(request, cacheDurationInMs)
        .then(handleResponse)
        .then(handleSuccess)
        .catch(handleError)
        .finally(handleDone);
    },
    [
      disabled,
      queryUrl,
      memoHeaders,
      cacheDurationInMs,
      handleLoading,
      handleSuccess,
      handleError,
      handleDone,
    ]
  );

  const cancelQuery = useCallback(() => {
    abortControllerRef.current?.abort();
  }, []);

  // Initialise the query
  // Run query when it changes (+mount)
  useEffect(() => {
    const debouncedInitQuery = debounceDurationInMs
      ? debounce(initQuery, debounceDurationInMs)
      : initQuery;
    debouncedInitQuery();

    return cancelQuery;
  }, [initQuery, cancelQuery, debounceDurationInMs]);

  // Run when internet is reconnected
  useEventListener("online", () => initQuery(true), !refetchOnReconnect);

  useInterval(
    () => {
      clearExpiredCache();
    },
    ONE_MIN_IN_MS,
    { executeImmediately: true }
  );

  return {
    status,
    data,
    errorMsg,
    refresh: initQuery,
    cancel: cancelQuery,
    isInit: status === QueryStatus.INIT,
    isSuccess: status === QueryStatus.SUCCESS,
    isError: status === QueryStatus.ERROR,
    isLoading: status === QueryStatus.LOADING,
    isDisabled: disabled,
  };
}

async function fetchQueryWithCache(
  request: Request,
  cacheDurationInMs?: number
): Promise<Response> {
  const cachedResponse = await getFromCache(request, cacheDurationInMs);

  if (cachedResponse) return cachedResponse;

  // Fetching new data since the cache does not exist or is expired.
  return fetch(request).then(async (response) => {
    // Save a valid response in cache
    if (response.ok && cacheDurationInMs) {
      // Response can be only used once, so it is cloned here.
      await addToCache(request, response.clone(), cacheDurationInMs);
    }

    return response;
  });
}
